import React, { useState, useEffect } from "react";
import {
  Text,
  Flex,
  Input,
  Button,
  ModalProvider,
  Label,
  Stripe,
  Image,
  TosCheckbox,
} from "components";
import { FoyerProduct } from "./";
import { toast } from "react-toastify";
import { stripeKey } from "utils";
import { PRODUCTS } from "api";
import * as S from "./FoyerStore.styled";
import { colors } from "consts";

export default ({ eventId, products, contactEmail }) => {
  const [product, setProduct] = useState();
  const [tab, setTab] = useState("about");
  const [quantity, setQuantity] = useState(1);
  const [cart, setCart] = useState([]);
  const [showCart, setShowCart] = useState(false);
  const [order, setOrder] = useState({});
  const [cardNumber, setCardNumber] = useState();
  const [cardExpiry, setCardExpiry] = useState();
  const [cardCVC, setCardCVC] = useState();
  const [stripe, setStripe] = useState();
  const [showCheckout, setShowCheckout] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tosAccepted, setTosAccepted] = useState(false);

  useEffect(() => {
    window.localStorage[`cart-${eventId}`] &&
      setCart(JSON.parse(window.localStorage[`cart-${eventId}`]));
    const _stripe = window.Stripe(stripeKey);
    setStripe(_stripe);
  }, [eventId]);

  const toggleTos = () => setTosAccepted(!tosAccepted);

  const addToCart = () => {
    if (quantity > 0) {
      const _obj = {
        ...product,
        quantity: quantity,
      };
      let _cart = window.localStorage[`cart-${eventId}`]
        ? JSON.parse(window.localStorage[`cart-${eventId}`])
        : [];
      const _existing = _cart.filter((_item) => _item.id === product.id);
      if (_existing.length > 0) {
        _cart.forEach((_item) => {
          _item.id === product.id &&
            (_item.quantity = parseInt(_item.quantity) + parseInt(quantity));
          return _item;
        });
      } else {
        _cart.push(_obj);
      }

      setCart(_cart);
      setQuantity(1);
      window.localStorage.setItem(`cart-${eventId}`, JSON.stringify(_cart));
      toast("Item(s) added to cart", { autoClose: 3000 });
    } else {
      toast.error("Quantity needs to be more than 0", { autoClose: 3000 });
    }
  };

  const calculateCartTotal = () => {
    let _total = 0;
    for (var i in cart)
      _total =
        _total +
        (cart[i].price + (cart[i].shipping ? cart[i].shipping : 0)) *
          cart[i].quantity;
    return _total / 100;
  };

  const calculateCartSubTotal = () => {
    let _total = 0;
    for (var i in cart) _total = _total + cart[i].price * cart[i].quantity;
    return _total / 100;
  };

  const calculateCartShipping = () => {
    let _total = 0;
    for (var i in cart)
      _total =
        _total + (cart[i].shipping ? cart[i].shipping : 0) * cart[i].quantity;
    return _total / 100;
  };

  const updateOrder = (e) => {
    order[e.target.name] = e.target.value;
    setOrder(order);
  };

  const purchase = async () => {
    setLoading(true);
    if (!order.email) {
      toast.error("Please enter an email for this order", { autoClose: 5000 });
    } else if (!order.name) {
      toast.error("Please enter a name for this order", { autoClose: 5000 });
    } else if (!order.street) {
      toast.error("Please enter a street for this order", { autoClose: 5000 });
    } else if (!order.city) {
      toast.error("Please enter a city for this order", { autoClose: 5000 });
    } else if (!order.state) {
      toast.error("Please enter a state for this order", { autoClose: 5000 });
    } else if (!order.zip) {
      toast.error("Please enter a zip for this order", { autoClose: 5000 });
    } else if (!cardNumber || cardNumber._empty || cardNumber._invalid) {
      toast.error("Please enter a valid card number", { autoClose: 5000 });
    } else if (!cardExpiry || cardExpiry._empty || cardExpiry._invalid) {
      toast.error("Please enter your card's expiration date", {
        autoClose: 5000,
      });
    } else if (!cardCVC || cardCVC._empty || cardCVC._invalid) {
      toast.error("Please enter your card's CVC", { autoClose: 5000 });
    } else {
      try {
        const _token = await stripe.createToken(cardNumber);
        order.token = _token.token.id;
        order.products = cart;
        order.address =
          order.street +
          ", " +
          order.city +
          ", " +
          order.state +
          ", " +
          order.zip;
        delete order.street;
        delete order.city;
        delete order.state;
        delete order.zip;
        const _purchase = await PRODUCTS.purchase(eventId, order);
        if (_purchase.statusText === "Created" && _purchase.status === 201) {
          toast.success(
            "Order created successfully, you will receive an email with your receipt",
            { autoClose: 6000 }
          );
          window.localStorage.removeItem(`cart-${eventId}`);
          setCart([]);
          setShowCheckout(false);
        } else {
          toast.error("An unexpeced error occured while creating your order", {
            autoClose: 5000,
          });
        }
        setLoading(false);
      } catch (err) {
        toast.error("An unexpeced error occured while creating your order", {
          autoClose: 5000,
        });
      }
    }
    setLoading(false);
  };

  const updateCartQuantity = (qty, item) => {
    if (qty < 0) {
      toast.error("Quantity cannot be below 0", { autoClose: 3000 });
    } else {
      const _cart = [...cart];
      const _updated = _cart.filter((_item) => {
        if (_item.id === item.id) {
          _item.quantity = qty;
        }
        return _item;
      });
      setCart(_updated);
      window.localStorage[`cart-${eventId}`] = JSON.stringify(_updated);
    }
  };

  const removeItem = (item) => {
    const _cart = [...cart];
    const _updated = _cart.filter((_item) => _item.id !== item.id);
    setCart(_updated);
    window.localStorage[`cart-${eventId}`] = JSON.stringify(_updated);
  };

  return (
    <Flex direction="column" align="flex-start">
      <Flex justify="space-between">
        <Flex gap="32px">
          <Text
            size="50px"
            responsive={{ phonePlus: { size: "35px" } }}
            className="canela"
          >
            Support Us
          </Text>
          <Text size="12px" style={{ maxWidth: "500px" }}>
            Digital downloads and physical products will be delivered by the
            film team. If you have questions about your order please click
            {' '}<a href={`mailto:${contactEmail}`}>Contact Film Team</a>.
          </Text>
        </Flex>
        <Button className="outline-white" onClick={() => setShowCart(true)}>
          <Image
            width="24px"
            src={require("assets/images/icon-shopping-cart.svg").default}
            margin="0 8px 0 0"
          />
          <Text margin="0 0 2px 0">Cart ({cart.length})</Text>
        </Button>
      </Flex>

      {product && (
        <>
          <Flex margin="12px 0 0" pointer onClick={() => setProduct(null)}>
            <S.Arrow
              src={require("assets/images/icon-chevron-left.svg").default}
            />
            <Text margin="0 0 0 8px">Back</Text>
          </Flex>

          <Flex
            padding="22px 0 120px"
            align="flex-start"
            justify="space-between"
            wrap={1}
          >
            <S.ImageWrap>
              <S.MainImage
                url={
                  product.thumbnail ||
                  require("assets/images/icon-shopping-cart.svg").default
                }
              />
            </S.ImageWrap>

            <Flex width="500px" direction="column" align="flex-start">
              <Text weight="bold" size="38px" margin="0 0 16px">
                {product.title}
              </Text>
              <Text margin="16px 0 33px" size="64px" className="canela">
                ${product.price / 100}
              </Text>
              <Flex justify="flex-start" align="flex-start">
                <Flex
                  width="90px"
                  margin="0 16px 0 0"
                  direction="column"
                  align="flex-start"
                >
                  <Input
                    min={0}
                    value={quantity}
                    height="40px"
                    type="number"
                    onChange={(e) => setQuantity(e.target.value)}
                  />
                  <Text size="11px">Qty</Text>
                </Flex>

                <Button onClick={() => addToCart()}>Add to Cart</Button>
              </Flex>

              <Flex margin="85px 0 40px">
                <S.Tab onClick={() => setTab("about")} active={tab === "about"}>
                  Description
                </S.Tab>
              </Flex>

              {tab === "about" && (
                <Text size="14px" color="#929292">
                  {product.description}
                </Text>
              )}
            </Flex>
          </Flex>
        </>
      )}

      <Flex
        direction="column"
        align="flex-start"
        responsive={product ? { phonePlus: { display: "none" } } : null}
      >
        {product && (
          <Text weight="bold" size="38px">
            Other Items
          </Text>
        )}

        <Flex
          padding="32px 0"
          align="flex-start"
          wrap={1}
          responsive={{ tablet: { margin: "0" } }}
        >
          {products?.map((_product) =>
            product ? (
              _product.id !== product.id && (
                <FoyerProduct
                  key={_product.id}
                  product={_product}
                  onClick={() => setProduct(_product)}
                />
              )
            ) : (
              <FoyerProduct
                key={_product.id}
                product={_product}
                onClick={() => setProduct(_product)}
              />
            )
          )}
        </Flex>
      </Flex>

      <ModalProvider
        width="990px"
        margin="64px"
        show={showCart}
        close={() => setShowCart(false)}
      >
        <Flex
          direction="column"
          align="flex-start"
          justify="space-between"
          padding="32px"
        >
          <Flex justify="center" margin="0 0 32px">
            <Text center size="19px">
              Shopping Cart
            </Text>
          </Flex>

          {cart.length > 0 ? (
            <>
              <Flex justify="space-between" align="flex-start" wrap={1}>
                <Flex
                  direction="column"
                  align="flex-start"
                  width="50%"
                  responsive={{ tablet: { width: "100%" } }}
                >
                  <Text margin="0 0 54px">Order Summary</Text>
                  <S.Items direction="column" align="flex-start">
                    {cart &&
                      cart.map((_item) => (
                        <S.Item
                          relative
                          key={_item.id}
                          margin="0 0 32px"
                          justify="space-between"
                        >
                          <S.Thumbnail src={_item.thumbnail} />

                          <Flex direction="column" align="flex-start">
                            <Text>{_item.title}</Text>

                            <Flex
                              width="90px"
                              padding="16px 0 16px"
                              direction="column"
                              align="flex-start"
                            >
                              <Text size="11px" margin="0 0 4px">
                                Quantity
                              </Text>
                              <Input
                                min="0"
                                value={_item.quantity}
                                height="40px"
                                type="number"
                                onChange={(e) =>
                                  updateCartQuantity(e.target.value, _item)
                                }
                              />
                            </Flex>

                            <Text size="36px">
                              $
                              {(
                                ((_item.price +
                                  (_item.shipping ? _item.shipping : 0)) /
                                  100) *
                                _item.quantity
                              ).toFixed(2)}
                            </Text>
                          </Flex>

                          <S.Delete
                            src={require("assets/images/icon-x.svg").default}
                            onClick={() => removeItem(_item)}
                          />
                        </S.Item>
                      ))}
                  </S.Items>
                  <Flex padding="48px 0 0" className="tablet-hide">
                    <Image
                      width="12px"
                      src={require("assets/images/icon-arrow-left.svg").default}
                      margin="0 8px 0 0"
                    />
                    <Text
                      pointer
                      onClick={() => setShowCart(false)}
                      size="14px"
                    >
                      Continue Shopping
                    </Text>
                  </Flex>
                </Flex>

                <Flex
                  padding="0 0 0 80px"
                  direction="column"
                  align="flex-start"
                  width="50%"
                  responsive={{ tablet: { width: "100%", padding: "0" } }}
                >
                  <Text size="19px" margin="0 0 54px">
                    Total
                  </Text>

                  <Flex justify="space-between" margin="0 0 32px">
                    <Text>Subtotal</Text>
                    <Text>${calculateCartSubTotal()}</Text>
                  </Flex>

                  <Flex justify="space-between" margin="0 0 32px">
                    <Text>Shipping</Text>
                    <Text>${calculateCartShipping()}</Text>
                  </Flex>

                  <Flex justify="space-between" margin="0 0 50px">
                    <Text>Total</Text>
                    <Text>${calculateCartTotal()}</Text>
                  </Flex>

                  <Button
                    icon={require("assets/images/icon-lock.svg").default}
                    width="100%"
                    className="medium"
                    onClick={() => {
                      setShowCart(false);
                      setShowCheckout(true);
                    }}
                  >
                    Secure Checkout
                  </Button>
                </Flex>
              </Flex>
            </>
          ) : (
            <Flex direction="column" padding="100px 0">
              <Text margin="0 0 16px">Your shopping cart is empty</Text>
              <Button
                className="medium outline-white"
                onClick={() => setShowCart(false)}
              >
                Continue Shopping
              </Button>
            </Flex>
          )}
        </Flex>
      </ModalProvider>

      <ModalProvider
        width="515px"
        margin="64px"
        show={showCheckout}
        close={() => setShowCheckout(false)}
      >
        <Flex direction="column" align="flex-start" padding="60px">
          <Text size="19px" margin="0 0 24px">
            Pay with card
          </Text>

          <Label>Email</Label>
          <Input
            value={order.email}
            name="email"
            onChange={updateOrder}
            margin="0 0 24px"
            placeholder="email"
          />

          <Label>Card Information</Label>
          <Stripe
            stripe={stripe}
            setCardNumber={(e) => setCardNumber(e)}
            setCardExpiry={(e) => setCardExpiry(e)}
            setCardCVC={(e) => setCardCVC(e)}
          />

          <Label>Name on card</Label>
          <Input
            value={order.name}
            name="name"
            onChange={updateOrder}
            margin="0 0 24px"
            placeholder="name"
          />

          <Label>Street</Label>
          <Input
            radius="6px"
            value={order.street}
            name="street"
            onChange={updateOrder}
            margin="0 0 24px"
            placeholder="street"
          />

          <Label>City</Label>
          <Input
            radius="6px"
            value={order.city}
            name="city"
            onChange={updateOrder}
            margin="0 0 24px -1px"
            placeholder="city"
          />

          <Flex align="start">
            <Flex direction="column" align="flex-start" relative>
              <Label>State</Label>
              <Flex column gap="4px" margin="0 0 24px" align="start">
                <Input
                  radius="6px 0px 0px 6px"
                  value={order.state}
                  name="state"
                  onChange={updateOrder}
                  placeholder="state"
                />
                <Text color={colors.gray700} size="12px">
                  Not in the US? enter "NA"
                </Text>
              </Flex>
            </Flex>
            <Flex direction="column" align="flex-start" relative>
              <Label>Zip</Label>
              <Flex column gap="4px" margin="0 0 24px" align="start">
                <Input
                  radius="0px 6px 6px 0px"
                  value={order.zip}
                  name="zip"
                  onChange={updateOrder}
                  margin="0 0 0px -1px"
                  placeholder="zip"
                />
                <Text color={colors.gray700} size="12px">
                  Not in the US? enter "00000"
                </Text>
              </Flex>
            </Flex>
          </Flex>

          <TosCheckbox
            tosAccepted={tosAccepted}
            setTosAccepted={setTosAccepted}
            toggleTos={toggleTos}
            light
          />

          <Text size="14px" margin="8px 0 0">
            **Note: Please be aware this charge will appear as SHOW&#38;TELL on
            your credit card statement.**
          </Text>

          {loading ? (
            <Button margin="16px 0 64px" className="medium" width="100%">
              Submitting order...
            </Button>
          ) : (
            <Button
              margin="16px 0 64px"
              icon={require("assets/images/icon-lock.svg").default}
              className={`medium ${!tosAccepted ? "disabled" : ""}`}
              width="100%"
              onClick={() => tosAccepted && purchase()}
            >
              Pay ${calculateCartTotal().toFixed(2)}
            </Button>
          )}

          <Flex justify="center">
            <a
              href="https://stripe.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image
                width="100px"
                src={require("assets/images/powered-by-stripe.png").default}
              />
            </a>
            <Text
              color="rgba(255, 255, 255, 0.3)"
              size="12px"
              margin="0 0 0 16px"
            >
              |
            </Text>
            <a href="/" target="_blank" rel="noopener noreferrer">
              <Text
                color="rgba(255, 255, 255, 0.3)"
                size="12px"
                margin="0 0 0 16px"
              >
                Terms
              </Text>
            </a>
            <a href="/" target="_blank" rel="noopener noreferrer">
              <Text
                color="rgba(255, 255, 255, 0.3)"
                size="12px"
                margin="0 0 0 16px"
              >
                Privacy
              </Text>
            </a>
          </Flex>
        </Flex>
      </ModalProvider>
    </Flex>
  );
};
