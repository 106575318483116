import React from "react";
import {
  BackgroundProvider,
  Flex,
  FoyerAbout,
  FoyerQA,
  FoyerStore,
} from "components";

export default ({
  tab,
  aboutSettings,
  eventId,
  comments,
  products,
  photos,
  faqSectionTitle,
  contactEmail,
  showForwardButton,
}) => (
  <BackgroundProvider summary="Foyer component">
    <Flex
      responsive={{ tablet: { padding: "30px" } }}
      padding="48px 105px"
      direction="column"
      align="flex-start"
    >
      {{
        about: (
          <FoyerAbout
            eventId={eventId}
            showForwardButton={showForwardButton}
            aboutSettings={aboutSettings}
            contactEmail={contactEmail}
            photos={photos}
          />
        ),
        qa: (
          <FoyerQA
            eventId={eventId}
            aboutSettings={aboutSettings}
            comments={comments}
            faqSectionTitle={faqSectionTitle}
          />
        ),
        store: <FoyerStore contactEmail={contactEmail} eventId={eventId} products={products} />,
      }[tab] || <></>}
    </Flex>
  </BackgroundProvider>
);
